import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const EN = {
  "mainCommon-HEIPM": "HEIP-ManageSystem",
  "mainCommon-Welcome": "Welcome, Please login",
  "mainCommon-Login": "Login",
};
const ZH = {
  "mainCommon-HEIPM": "HEIP-管理系统",
  "mainCommon-Welcome": "欢迎您，请登录",
  "mainCommon-Login": "登录",
};
export const DefaultLanguage = "EN"
i18n.use(initReactI18next).init({
  resources: { // 这里给登录页默认加点文字，因为登录页没有去服务器请求国际化资源
    EN: {translations: EN},
    ZH: {translations: ZH},
  },
  fallbackLng: DefaultLanguage,
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});

export default i18n;
