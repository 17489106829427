/**
 * 捕获未被捕获的Promise内部的错误
 */
export const unhandledrejection = function() {
    window.addEventListener('unhandledrejection', function(event) {
        console.log('event', event)
        const {message, stack} = event.reason;
        console.log(message)
        console.log(stack)
    })
}
/**
 *  1. 能捕获到js执行错误，不能捕获带有src的标签元素的加载错误。
    2. 参数对应5个值（错误信息，所在文件，行，列，错误信息）
    3. 函数体内用return true可以不让异常信息输出到控制台
 */
export const jsError = function() {
    //@ts-ignore
    window.onerror = (errormessage: string, url: string, line: string, column: string, error: any) => {
        console.log("======================================================================")
        console.log("url:{0}".replace('{0}', url))
        console.log("error:{0}".replace('{0}', error))
        console.log("errormessage:{0}".replace('{0}', errormessage))
        console.log("line:{0}".replace('{0}', line))
        console.log("column:{0}".replace('{0}', column))
        console.log("======================================================================") 
    }
}
/**
 * 1. 为捕获状态时（第三个参数为true）能捕获到js执行错误，也能捕获带有src的标签元素的加载错误。
    为冒泡状态时（第三个参数为false）能捕获到js执行错误，不能捕获带有src的标签元素的加载错误。
    2. 参数对应1个值，异常事件，错误信息都在里面
    3. 函数体内用preventDefault可以不让异常信息输出到控制台
 */
export const jsError2 = function() {
    window.addEventListener('error', (event) => {
        const {message, stack} = event.error;
        console.log(message)
        console.log(stack)
    }, true)
}

export const errorCatch = function() {
    unhandledrejection();
    jsError();
}