import { DefaultLanguage } from "@/lang/lang"



/**
 * 设置语言
 */
export const getLanguage = (): string => {
    return localStorage.getItem('language') || DefaultLanguage
}
export const setLanguage = (language: string = DefaultLanguage): void => {
    localStorage.setItem('language', language) 
}

/**
 * 设置访问域名
 */
const DefaultOrigin = (window as any).location.origin;
const DefaultOriginService = "https://heipapi.bfsuma.com";
export const getOrigin = (): string => {
    return DefaultOrigin
}
export const setOrigin = (origin = DefaultOrigin): void => {
    localStorage.setItem('origin', origin) 
}

export const getOriginService = (): string => {
    return DefaultOriginService
}
export const setOriginService = (origin = DefaultOriginService): void => {
    localStorage.setItem('originService', origin) 
}
setOrigin()
setOriginService()


/**
 * 区分不同系统
 */
export enum SystemKey {OA = "oa", BUSINESS = "business", HOME = "home",RC="rc"}
export const getSystem = (): SystemKey => {
    const pathname = window.location.hash;
    if (pathname.includes('/oa')) {
        return SystemKey.OA
    } else if (pathname.includes('/business')) {
       return SystemKey.BUSINESS
    }else if(pathname.includes('/rc')){
       return SystemKey.RC
    } else {//默认值 但是要注意经销商是取默认值 rc 内部用户才是取home
       return getIsUc()==='true'?SystemKey.HOME:SystemKey.RC
    }
}

/**
 * 设置token，user,isUc,membInfo
 */
export const getUser = (): any =>  { // 获取用户信息
    return JSON.parse(sessionStorage.getItem('user') || '{}')  
}
export const getToken =(): string => { // 获取用户token信息
    return sessionStorage.getItem('token')  || ''
}
export const getIsUc =(): string => { // 获取用户isUc信息
    return sessionStorage.getItem('isUc')  || ''
}
export const getMembInfo =(): string => { // 获取经销商信息
    return JSON.parse(sessionStorage.getItem('membInfo')  || '{}')
}
export const setToken =(token: string): void => { // 设置用户token信息
    sessionStorage.setItem('token', token)
}
export const setUser =(user: string): void => { // 设置用户token信息
    sessionStorage.setItem('user', user)
}
export const setIsUc =(isUc: string): void => { // 设置用户isUc信息
    sessionStorage.setItem('isUc', isUc)
}
export const setMembInfo =(membInfo: string): void => { // 设置用户isUc信息
    sessionStorage.setItem('membInfo', membInfo)
}

//跳转到其他系统url携带的参数
export const splicingAuth = (preview: any = '') => {
    return `?auth=${encodeURI(JSON.stringify(getUser()))}&preview=${preview}`
}

//跳转到rc系统url携带的参数
export const rcSplicingAuth = (preview: any = '') => {
    return `?auth=${encodeURI(JSON.stringify(getUser()))}&preview=${preview}&isUc=${getIsUc()}&membInfo=${encodeURI(JSON.stringify(getMembInfo()))}`
}


export const isEqualObject = (prevObj: any, nextObj: any) => {
    if (!prevObj || !nextObj) return prevObj === nextObj;
    const prevProps = Object.getOwnPropertyNames(prevObj);
    const nextProps = Object.getOwnPropertyNames(nextObj);
    if (prevProps.length !== nextProps.length) return false;
    for (let i = 0; i < prevProps.length; i++) {
        const propName = prevProps[i];
        const prevObjValue = prevObj[propName];
        const nextObjValue = nextObj[propName];
        if (prevObjValue instanceof Object) {
            if (!Object.is(JSON.stringify(prevObjValue), JSON.stringify(nextObjValue))) return false
        } else {
            if (!Object.is(prevObjValue, nextObjValue)) return false
        }
    }
    return true
}
export const sort = (a: any, b: any, type: any) => {
    if (!a && !b)return;
    if ( type === 'DATE') {
        return new Date(a).getTime() - new Date(b).getTime()
    } else if ( type === 'EN') {
        return String(a).charCodeAt(0) - String(b).charCodeAt(0)
    } else if ( type === 'zh-CN') {
        return a.localeCompare(b, 'zh-CN')
    }
}