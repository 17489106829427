import { Suspense, lazy } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loading from '../components/Loading';
import publicRoute from './public'; // 不需要权限的页面
import homeRoute from './home'; // 首页
const App = lazy(() => import('@layouts/App'));

export const routes: Array<RouteConfig> = [
    ...homeRoute,
];
const Router = () => {
    return (
        <HashRouter>
            <Suspense fallback={ <Loading type="fullscreen"/> }>
                <Switch>
                    {
                        publicRoute.map((route, i) => (
                            <Route
                                key={i}
                                path={route.path}
                                exact={true}
                                component={ route.component }
                            />
                        ))
                    }
                    <Route path="/" render= { routeProps => (
                        <App {...routeProps} routes={ routes }/>
                    )}/>
                </Switch>
            </Suspense>
        </HashRouter>
    )
}
export default Router