import { reduxStoreTypes } from '@type/redux-store';
import GlobalConfig from '@utils/global';
export const ADD_TAB:reduxStoreTypes.TabType = 'ADD_TAB';
export const DELETE_TAB:reduxStoreTypes.TabType = 'DELETE_TAB';
export const addTab = (params: Omit<reduxStoreTypes.TabAction, 'type'>) => (dispatch: any) => {
    const history = new GlobalConfig().getHistory()
    const pathname = history.location?.pathname;
    dispatch({
        type: ADD_TAB,
        pathname,
        ...params
    })
}
export const deleteTab = (params: Omit<reduxStoreTypes.RemoveTabAction, 'type'>) => (dispatch: any, getState: any) => {
    const { tabs } = getState();
    const globalConfig = new GlobalConfig()
    const history = globalConfig.getHistory()
    const aliveScope = globalConfig.getAliveScope()
    const curPath = history.location?.pathname;
    const {
        pathname = curPath,
        removePath, 
        nextToPathname, 
        reload,
    } = params
    const tabIndex = tabs.findIndex((route: RouteConfig) => route.path === removePath); // 要关闭的tab的位置
    const curIndex = tabs.findIndex((route: RouteConfig) => route.path === pathname); // 当前路由的tab的位置
    if (tabIndex >= 1) { // 首页tab不能被删除
        if (tabIndex === curIndex) {
            if (!!nextToPathname) {
                if (reload) {
                    aliveScope?.dropScope(nextToPathname);
                }
                setTimeout(() => {
                    history.push(nextToPathname); // 缺少search
                }, 0)
            } else {
                // const path = tabs[tabIndex - 1]['path']; 
                // history.push(path)
                const path = tabs[tabIndex - 1]['path']; 
                history.push({
                    pathname: path,
                    search: tabs[tabIndex - 1]['params']?.search
                })
            }
        }
        const path = tabs[tabIndex - 1]['path']; 
        history.push(path)
    }
    dispatch({
        type: DELETE_TAB,
        ...params,
        tabIndex,
    })
}