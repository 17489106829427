
type aliveScopeType = ({
    drop: (args?: any) => void,
    dropScope:  (args?: any) => void,
    clear:  (args?: any) => void,
    getCachingNodes: any
}) | null | undefined
class GlobalConfig {
    private static instanceOfGlobal: GlobalConfig | null;
    private history: any = null;
    private aliveScope: aliveScopeType = null;
    constructor(history?: History, aliveScope?: aliveScopeType ) {
        if (GlobalConfig.instanceOfGlobal) {
            return GlobalConfig.instanceOfGlobal
        }
        this.history = history;
        this.aliveScope = aliveScope;
        GlobalConfig.instanceOfGlobal = this;
    }
    getHistory() {
        return this.history || {}
    }
    setHistory(history: History) {
        this.history = history;
    }
    getAliveScope() {
        return this.aliveScope
    }
    setAliveScope(aliveScope: aliveScopeType) {
        this.aliveScope = aliveScope
    }
    clear() {
        this.aliveScope?.clear()
        this.aliveScope = null
        this.history = null
        GlobalConfig.instanceOfGlobal = null
    }
}
export default GlobalConfig