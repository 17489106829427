import { lazy } from 'react';
const Home = lazy(() => import('@pages/home/Home'));
const Business = lazy(() => import('@pages/business/Business'));
const OA = lazy(() => import('@pages/oa/OA'));
const RC = lazy(() => import('@pages/rc/RC'));
const homeRoute =  [
    {
        path: "/",
        name: '首页',
        component: Home
    },
    {
        path: "/business",
        name: 'HEIP-business',
        component: Business 
    },

    //业务板块审批页
    {
        path: "/business/:source/:procDefKey/:instId",
        name: 'HEIP-business',
        component: Business 
    },
    {
        path: "/oa",
        name: 'HEIP-OA',
        component: OA 
    },
    {
        path: "/rc",
        name: 'HEIP-RC',
        component: RC 
    }
]
export default homeRoute