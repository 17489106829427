import { lazy } from 'react';

const Login = lazy(() => import('@pages/login/Login'));
const NotFound = lazy(() => import('@pages/404/NotFound'));
const homeRoute = [
    {
        path: "/login",
        name: '登录页',
        component: Login
    },
    {
        path: "/404",
        name: '404',
        component: NotFound
    }
]
export default homeRoute